import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/automatyzacja-tlo.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    wyzwalacze: file(relativePath: { eq: "wyzwalacze.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    warunki: file(relativePath: { eq: "warunki.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    akcje: file(relativePath: { eq: "akcje.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    karta: file(relativePath: { eq: "karta-osoby.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    strata1: file(relativePath: { eq: "utracona-szansa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    strata2: file(relativePath: { eq: "utracona-szansaa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const Automatyzacja = ({ data }) => {
  return (
    <Artykul
      title="Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?"
      metaTitle="Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?"
      metaDescription="Automatyzacja procesu sprzedaży źródłem oszczędności? Nowoczesne oprogramowanie CRM. Sprawdź!"
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >
        Automatyzacja w pracy handlowca jest jednym z najskuteczniejszych
        narzędzi w usprawnianiu pracy oraz oszczędzaniu cennego czasu.
        Szczególnie w przypadku nieskomplikowanych i regularnie powtarzających
        się zadań może ona znacząco zwiększyć efektywność i ulepszyć procesy
        pozyskiwania klientów.
      </p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="PEMvVqP7-Ao"
          title="Jak dzięki automatyzacji sprzedaży zaoszczędzić czas automatyzując powtarzalne czynności w systemie CRM?"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Automatyzacja procesów sprzedażowych - co to takiego?
      </h2>
      <p>
        Automatyzacja sprzedaży jest tematem popularnym obecnie zarówno wśród
        sprzedawców B2B, jak i B2C. Pod pojęciem tym kryją się wszystkie procesy
        lub ich grupy, w których udział człowieka nie jest potrzebny. Pominięcie
        konieczności ręcznego wprowadzania informacji czy transakcji pozwala tym
        samym handlowcowi skupić się na pozostałych elementach procesu, które
        wymagają jego uwagi.
      </p>
      <p>
        {" "}
        Współcześnie coraz więcej przedsiębiorstw decyduje się na zastosowanie
        automatyzacji w różnych dziedzinach i działaniach handlowych oraz za
        pomocą różnorodnych narzędzi. Warto jednak pamiętać, iż celem
        automatyzacji sprzedaży nie jest dążenie do pełnego zastąpienia
        handlowca w działaniach sprzedażowych - zamiast tego powinna ona
        wspierać jego codzienne działania, umożliwiając mu coraz to lepsze
        wykonywanie jego pracy.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zautomatyzować sprzedaż w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i zautomatyzować proces sprzedażowy
            </Link>
          </div>
          </div>
    </section>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        System CRM w automatyzacji sprzedaży
      </h2>
      <p>
        {" "}
        <strong>Systemy zarządzania relacjami z klientami (CRM)</strong>{" "}
        pomagają pracownikom działów sprzedaży w organizacji ich codziennych
        aktywności oraz dążeniu do pozyskiwania nowych klientów i zawieraniu
        kolejnych transakcji.
      </p>

      <p>
        {" "}
        Za pośrednictwem systemów CRM automatyzację można wprowadzać w wielu
        różnych obszarach, takich jak na przykład:
      </p>
      <p>
        <ul>
          <li>gromadzenie leadów z różnych miejsc;</li>
          <li>koordynacja i efektywny transfer wiedzy;</li>
          <li>zarządzanie wiadomościami e-mail z wielu skrzynek.</li>
        </ul>
      </p>
      <p>
        {" "}
        Część systemów CRM jest także w stanie automatyzować poszczególne
        działania handlowców, wykonując za nich dane aktywności.{" "}
      </p>
      <p>
        {" "}
        Należy jednak pamiętać, że automatyzacja sprzedaży działa najlepiej, gdy
        wykorzystujemy ją w przypadku prostych czynności, które wielokrotnie się
        powtarzają - wtedy bowiem zyskamy dzięki temu najwięcej korzyści i
        efektów. W celu wskazania działań, które warto w ten sposób usprawnić
        niezbędne może okazać się przyjrzenie się całemu procesowi sprzedaży w
        przedsiębiorstwie i działaniom samych handlowców oraz
        najpowszechniejszych wśród nich aktywności.
      </p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Jak to wygląda w praktyce?
      </h2>
      <p>
        {" "}
        U podstaw automatyzacji sprzedaży stoją trzy główne filary -
        <strong>
          {" "}
          wyzwalacz (dowolna aktywność w procesie), z góry ustalone warunki oraz
          akcja - podjęcie działania.
        </strong>{" "}
        Poniżej przedstawiamy niektóre z wyzwalaczy, które w naszym systemie CRM
        mogą wywołać daną akcję:{" "}
      </p>
      <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.wyzwalacze]}
        alts={["Przykładowe wyzwalacze w procesie automatyzacji"]}
      />
      <p>
        {" "}
        Po ustaleniu konkretnego wyzwalacza system automatycznie określa, czy
        spełnione zostały poszczególne warunki prowadzące do wywołania
        określonej akcji. Tutaj możemy zobaczyć niektóre z takich warunków:
      </p>
      <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.warunki]}
        alts={["Przykładowe warunki w procesie automatyzacji systemu CRM"]}
      />
      <p>
        Ostatnim etapem w omawianym procesie automatyzacji sprzedaży jest
        podjęcie przez system założonych działań. Oto przykładowe z takich
        akcji:
      </p>
      <p>
        {" "}
        Po ustaleniu konkretnego wyzwalacza system automatycznie określa, czy
        spełnione zostały poszczególne warunki prowadzące do wywołania
        określonej akcji. Tutaj możemy zobaczyć niektóre z takich warunków:
      </p>
      <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.akcje]}
        alts={["Przykładowe akcje w procesie automatyzacji"]}
      />
      <p>
        {" "}
        Zarządzanie potencjalną sprzedażą (szansą) - zmianami jej statusu na
        wygranie (np. pozyskanie klienta czy projektu) lub przegranie (utrata
        klienta/projektu) należy do jednych z podstawowych zadań systemu CRM.
        Dzięki gromadzeniu i przechowywaniu bazy kontrahentów wraz z historią
        współpracy system taki staje się kluczowym narzędziem w pracy każdego
        handlowca.
      </p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Przykład 1 – wygrana szansa sprzedaży, zmiana tagu
      </h2>
      <p>
        {" "}
        Pracownik działu sprzedaży zdobywa klienta i zamyka sprzedaż poprzez
        oznaczenie jej jako “wygrana”. Automatycznie system CRM rozpoznaje
        warunek - wygranie - i zmienia status kontrahenta na aktywną współpracę,
        natomiast w przypadku osoby kontaktowej podpiętej pod tą firmę dodaje
        tag, np. “klient_produktX”. Dzięki temu w systemie CRM segmentujemy nie
        tylko kontrahentów, ale również i osoby kontaktowe. Pozwala to później
        na wiele działań marketingowych, jak np. mailingi do określonej grupy
        odbiorców tworzonej na podstawie określonych tagów.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.karta]}
        alts={["Działanie CRM online - zarządzanie kartą osoby kontrahenta"]}
      />
      <p>
        {" "}
        <strong>Wyzwalacz:</strong> zmiana statusu szansy sprzedaży
      </p>
      <p>
        {" "}
        <strong>Warunek:</strong> szansa "wygrana"
      </p>
      <p>
        {" "}
        <strong>Akcja:</strong> zmiana statusu na “aktywna współpraca” w karcie
        kontrahenta oraz dodanie tagu “KLIENT_PRODUKTX” w karcie osoby
        kontaktowej
      </p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Przykład 2 – przegrana szansa sprzedaży, zmiana tagu
      </h2>
      <p>
        {" "}
        Podobna segmentacja może również zachodzić w przypadku szans utraconych.
        W przypadku usprawnienia danego produktu bądź usługi można za
        pośrednictwem mailingu poinformować dawnego kontrahenta o takich
        nowościach i w przypadku jego zainteresowania będzie to okazją do
        rozpoczęcia rozmów sprzedażowych na nowo.{" "}
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.strata1]}
        alts={["Zarządzanie utraconą szansą sprzedaży"]}
      />
      <p>
        {" "}
        <strong>Wyzwalacz:</strong> zmiana statusu szansy sprzedaży{" "}
      </p>
      <p>
        {" "}
        <strong>Warunek:</strong> szansa “przegrana”{" "}
      </p>
      <p>
        {" "}
        <strong>Akcja:</strong> zmiana statusu na “utracona szansa” w karcie
        kontrahenta oraz dodanie tagu “UTRACONASZANSA_PRODUKTX” w karcie osoby
        kontaktowej
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.strata2]}
        alts={["Zarządzanie utraconą szansą sprzedaży w platformie CRM"]}
      />
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Przykład 3 – automatyczne wiadomości SMS/e-mail
      </h2>
      <p>
        Automatyzacja procesów sprzedażowych jest narzędziem, które w znaczący
        sposób przyczyni się również do usprawnienia komunikacji pomiędzy firmą
        a jej kontrahentami. System obsługuje zarówno kontakt za pośrednictwem
        wiadomości SMS, jak i e-mailowych. Dzięki temu wdrożyć można chociażby
        cykliczne przypomnienia o konieczności serwisu czy też powiadomienia o
        nieuregulowanych fakturach. W automatyzatorze można więc na przykład
        zarejestrować następujące parametry:
      </p>
      <p>
        {" "}
        <strong>Wyzwalacz:</strong> czas, codziennie o godz. 12:00{" "}
      </p>
      <p>
        {" "}
        <strong>Warunek:</strong> proces rozpocznie się za X dni
      </p>
      <p>
        {" "}
        <strong>Akcja:</strong> wyślij SMS o treści X
      </p>
      <p>
        Zespół Questy nieustannie pracuje nad rozwojem tego mechanizmu,
        wdrażając – zgodnie z potrzebami – coraz to więcej dostępnych dla
        użytkowników funkcji.
      </p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Jakie korzyści niesie ze sobą automatyzacja sprzedaży z programem CRM?:
      </h2>
      <p>
        <ul>
          <li>
            <strong> Zerowy koszt integracji</strong> - nasze rozwiązanie działa
            w pełni samodzielnie i nie jest uzależnione od żadnej aplikacji
            zewnętrznej;
          </li>
          <li>
            <strong> Zwiększona efektywność</strong> - lepsze wykorzystanie
            zasobów przekłada się na większą skuteczność działania organizacji;
          </li>
          <li>
            <strong> Jedna solucja dla wielu problemów</strong> - nasze
            rozwiązanie oferuje zarówno możliwości automatyzacji, jak i system
            zarządzania relacjami z klientami;
          </li>
          <li>
            <strong> Wydajniejsze dysponowanie czasu pracowników</strong> -
            automatyzacja sprzedaży pozwala lepiej wykorzystywać zasoby
            personalne;
          </li>
          <li>
            <strong> Niższe koszty obsługi klienta</strong> - dzięki
            zautomatyzowaniu procesów sprzedażowych;
          </li>
          <li>
            <strong> Zero ukrytych kosztów!</strong> - wybierając nasze
            rozwiązanie wiesz doskonale ile oraz za jaką wartość płacisz.
          </li>
        </ul>
      </p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
        Wypróbuj już teraz i zaoszczędź nawet do 90% kosztów operacyjnych!*
      </h2>
      <p>
        {" "}
        Skuteczność automatyzacji sprzedaży w oszczędności czasu i zwiększaniu
        efektywności została już niejednokrotnie potwierdzona w praktyce. Na
        dłuższą metę handlowcy powinni skupiać się na pozyskiwaniu kontrahentów,
        a proste czynności ich pracy (takie jak tworzenie ofert czy zamówień)
        powinny zostać maksymalnie ułatwione. Warto więc zadbać o to, by Twoi
        pracownicy mogli zająć się czymś innym niż przeznaczanie czasu na
        obsługę każdego pojedynczego zamówienia, których każdego dnia może
        pojawić się nawet kilkaset.{" "}
      </p>
      <p>
        {" "}
        Dowiedz się więcej o naszych rozwiązaniach w automatyzacji sprzedaży i
        naszym systemie CRM oraz naszym doświadczeniu w tych obszarach i
        skontaktuj się z nami już teraz!{" "}
      </p>
      <p>
        {" "}
        *https://www.forrester.com/report/Predictions+2018+Automation+Alters+The+Global+Workforce/RES139991{" "}
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zautomatyzować sprzedaż w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i zautomatyzować proces sprzedażowy
            </Link>
          </div>
          </div>
    </section>
      <p>
        Sprawdź też co piszemy o:
        <ul>
          <li>
            <Link to="/co-to-jest-crm/"> Co to jest CRM?</Link>
          </li>
          <li>
            <Link to="/zarzadzanie-kampaniami-crm-szanse-sprzedazy/">
              {" "}
              Zarządzanie kampaniami w CRM{" "}
            </Link>
          </li>
          <li>
            <Link to="/integracja-crm-z-systemem-handlowym/">
              {" "}
              Integracja CRM z systemem handlowym{" "}
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Automatyzacja;
